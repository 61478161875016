import React from 'react';
import {IconProps} from './typing';

export const SpinnerIcon: React.FC<IconProps> = ({
  iconSize = '36',
}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSize}
    height={iconSize}
    display="block"
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 100 100"
    style={{background: 'none'}}
  >
    <circle
      cx="50"
      cy="50"
      r="38"
      fill="none"
      stroke="#5144c2"
      strokeDasharray="179.0707812546182 61.690260418206066"
      strokeWidth="10"
      transform="rotate(191.748 50 50)"
    >
      <animateTransform
        attributeName="transform"
        dur="1s"
        keyTimes="0;1"
        repeatCount="indefinite"
        type="rotate"
        values="0 50 50;360 50 50"
      ></animateTransform>
    </circle>
  </svg>
);
