const DEFAULT_SCENE7_EXTENSION = `.scene7Src.dir.json/srcSet/false/width//height//aspectRatio//focalPoint//optimized/true/.cache`;

export const getScene7Extension = (
  scene7ConfigExt?: string,
): string => {
  let scene7extension = DEFAULT_SCENE7_EXTENSION;
  if (scene7ConfigExt) {
    scene7extension = scene7ConfigExt;
  }
  return scene7extension;
};

export async function fetchImageSrc(url: string) {
  const promise = await fetch(url, {});
  const response = await promise?.json();

  if (promise.status >= 500) {
    throw new Error(
      response?.message || 'Error getting image from url: ' + url 
    );
  }
  return response?.src;
}
