import {CPOMastheadTranslations} from '../typing/translations';

export const defaultTranslation: CPOMastheadTranslations = {
  modals: {
    modalZip: {
      title: 'Set your location',
      description:
        'To find dealers, inventory, and special offers near you, we’ll need your zip code.',
      goCtaLabel: 'Go',
      privacyLinkCta: 'View Privacy Policy',
      inputMessage: {
        label: 'Enter your ZIP code',
        error: 'Invalid ZIP code',
        placeholder: 'Enter your Zip Code',
      },
    },
  },
  filterSection: {},
};
