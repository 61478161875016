import 'isomorphic-fetch';
import {fetchImageSrc, getScene7Extension} from './helpers';

export async function fetchScene7Image(
  imageSrc: string,
  scene7extension?: string,
  imageBaseUrl?: string,
): Promise<string> {
  const extension = getScene7Extension(scene7extension);
  const imgSrcUrl = `${imageBaseUrl}${imageSrc}${extension}`;
  const newImgSrc = await fetchImageSrc(imgSrcUrl);
  return newImgSrc;
}
