import React from 'react';
import {Inventory} from '../typing/inventory';
import {useSafeContext} from './use-safe-context';

export const InventoryContext = React.createContext<Inventory | undefined>(
  undefined,
);

export function useInventory(): Inventory {
  return useSafeContext(InventoryContext, 'Inventory');
}
