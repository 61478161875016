import {
  FeatureAppTrackingManager,
  FilterTracking,
  getDefaultTrackingEvent,
  LinkTracking,
} from '../hooks/use-tracking-manager';
import _ from 'lodash';
import {CategoryModels, ModelConfig} from '../typing/model';
import {GlobalModelData} from '../store/local-storage';

export interface Content {
  ItemCount?: number;
  ItemPosition?: number;
  CountdownTotalTime?: number;
  CountdownRemainingTime?: number;
  RSSFeedsCount?: number;
}

export interface Link {
  name?: string;
  url?: string;
}

const fieldsErrorCodes = {
  'FE01': 'Something went wrong',
  'INV01': 'Invalid Zip',
  'INV02': 'Communication Error',
  '': 'FE00',
};

/**
 * Simple example of creating a tagging event for a button click
 * @param contentData
 * @param linkData
 * @param trackingManager
 */
export const onVWButtonClickEvent = (
  contentData: Content,
  linkData: Link,
  trackingManager: FeatureAppTrackingManager,
) => {
  let customEvent = getDefaultTrackingEvent();

  // These objects properties required to be filled based on the data
  // that we are able to manipulate
  customEvent.environment = {
    ...customEvent.environment,
    commerce: true,
  };

  customEvent.eventInfo = {
    ...customEvent.eventInfo,
    contentId: 'Button click',
  };

  customEvent.content = {
    ...customEvent.content,
    ...contentData,
  };

  customEvent.user = {
    ...customEvent.user,
    loginStatus: false,
    // @ts-ignore
    customerType: undefined,
    customerContractsCount: undefined,
    dealerStatus: false,
  };

  customEvent.link = {
    ...linkData,
  };

  trackingManager &&
    trackingManager.trackFeatureAppGlobalEvent('VW_Button_Click', customEvent);
};

export const onVWBasicErrorMessageLoad = (
  trackingManager: FeatureAppTrackingManager,
  modelsConfigVar: CategoryModels[],
  globalModelData: GlobalModelData | undefined,
  errorId: string,
) => {
  let customEvent = getDefaultTrackingEvent();

  customEvent.environment = {
    ...customEvent.environment,
    commerce: false,
  };

  customEvent.user = {
    loginStatus: false,
  };

  if (globalModelData && globalModelData.slug) {
    let curModelConfig: ModelConfig | undefined;

    _.find(modelsConfigVar, function (categories) {
      _.find(categories.models, function (modelconfig) {
        if (modelconfig.slug === globalModelData?.slug) {
          curModelConfig = modelconfig;
          return true;
        }
        return false;
      });
    });

    customEvent.configuration = {
      Manufacturer: 'Volkswagen',
      BodyTypeName: curModelConfig?.category, //TODO Get category like sedan, compact that comes from the modelsdata. Either make query or use a apollo variable
      CarlineId: curModelConfig?.carlineId,
      CarlineName: curModelConfig?.name,
      PriceTotalCurrency: 'USD',
      UsedVehicle: false,
    };
  }

  customEvent.form = {
    orderId: '1',
  };

  customEvent.process = {
    Name: 'Validate Zip',
    StepName: 'Validate Zip',
    StepNumber: '1',
    Components: ['Zip Entry'],
  };

  customEvent.error = {
    code: errorId,
    message: fieldsErrorCodes[errorId],
  };

  trackingManager &&
    trackingManager.trackFeatureAppGlobalEvent(
      'VWBasic_ErrorMessage_Load',
      customEvent,
    );
};

/**
 * On Form Layer load event
 * @param name
 * @param trackingManager
 * @param store
 */
export const onVWBasicLinkClickEvent = (
  trackingManager: FeatureAppTrackingManager,
  contentId: string,
  link: {url: string; name: string; newTab?: boolean},
) => {
  let customEvent = getDefaultTrackingEvent();
  customEvent.eventInfo = {...customEvent.eventInfo, contentId: contentId};
  customEvent.link = link;
  trackingManager &&
    trackingManager.trackFeatureAppGlobalEvent(
      'VWBasic_Link_Click',
      customEvent,
    );
};

/**
 * handle zipcode change tracking
 * @param name
 * @param trackingManager
 */
export const onVWBasicLayerloadEvent = (
  trackingManager?: FeatureAppTrackingManager,
) => {
  let customEvent = getDefaultTrackingEvent();
  customEvent.eventInfo = {...customEvent.eventInfo, contentId: 'ZIP Layer'};

  customEvent.user = {
    loginStatus: false,
  };

  trackingManager &&
    trackingManager.trackFeatureAppGlobalEvent(
      'VWBasic_Layer_Load',
      customEvent,
    );
};

export const onVWBasicFilterSearchButtonClick = (
  filter: FilterTracking,
  link: LinkTracking,
  trackingManager: FeatureAppTrackingManager,
) => {
  let customEvent = getDefaultTrackingEvent();

  customEvent.filter = {
    ...customEvent.filter,
    ...filter,
  };

  customEvent.link = {
    ...customEvent.link,
    ...link,
  };

  trackingManager &&
    trackingManager.trackFeatureAppGlobalEvent(
      'VWBasic_FilterSearchButton_Click',
      customEvent,
    );
};

export const onVWBasicFilterSelectClick = (
  filter: FilterTracking,
  link: LinkTracking,
  trackingManager: FeatureAppTrackingManager,
) => {
  let customEvent = getDefaultTrackingEvent();

  customEvent.filter = {
    ...customEvent.filter,
    ...filter,
  };

  customEvent.link = {
    ...customEvent.link,
    ...link,
  };

  trackingManager &&
    trackingManager.trackFeatureAppGlobalEvent(
      'VWBasic_FilterSelect_Click',
      customEvent,
    );
};
