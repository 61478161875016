import React from 'react'
import { RangeSliderStyled } from './range-slider'

const RangeSlider: React.FC<any> = (props) => {

  return (
    <RangeSliderStyled {...props}/>
  )
}

export default RangeSlider