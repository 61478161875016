import {AppConstants} from '../utils/app-constants';

export const backgroundMediaMapping = {
  image: {
    image: {
      desktop: AppConstants.DesktopMastheadUrl,
      mobile: AppConstants.MobileMastheadUrl,
    },
    gif: {
      desktop: AppConstants.DesktopMastheadGifUrl,
      mobile: AppConstants.MobileMastheadGifUrl,
    },
  },
  video: {
    mp4: {
      desktop: AppConstants.DesktopMastheadVideoUrl,
      mobile: AppConstants.MobileMastheadVideoUrl,
    },
  },
};

export function strToBoolean(value: string) {
  return value === "true"
}