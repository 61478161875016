import React from 'react';

import {
  StyledCode,
  StyledContainer,
  StyledMessage,
  StyledWrapper
} from './styles';

interface ErrorComponentProps {
  readonly message1?: string;
  readonly message2?: string;
  readonly customHeight?: string;
}

export const ErrorMessage: React.FC<ErrorComponentProps> = ({
  message1,
  message2,
  customHeight
}) => (
  <StyledWrapper customHeight={customHeight} data-testid="error-message-wrapper">
    <StyledContainer>
      <StyledCode>{message1 ? message1 : 'Error'}</StyledCode>
      <StyledMessage>
        {message2 ? message2 : 'Something Went Wrong'}
      </StyledMessage>
    </StyledContainer>
  </StyledWrapper>
);
