import {ServerRequestV1} from '@feature-hub/server-request';
import {ServiceConfigurationV1} from '@volkswagen-onehub/service-config-provider';
import {useFeatureServices} from './use-feature-services';

function getServiceOrigin(
  serviceConfig: ServiceConfigurationV1,
  serverRequest: ServerRequestV1 | undefined,
): string {
  if (serviceConfig.urlOrigin) {
    return serviceConfig.urlOrigin;
  }

  if (serverRequest) {
    return new URL(serverRequest.url).origin;
  }

  return window.location.origin;
}

export function useStringifiedServiceConfigsServiceConfig():
  | string
  | undefined {
  const {
    'service-config-provider': serviceConfigProvider,
    's2:server-request': serverRequest,
  } = useFeatureServices();

  const serviceConfigsServiceConfig =
    serviceConfigProvider.configs['service-config'];

  if (!serviceConfigsServiceConfig) {
    return undefined;
  }

  const urlOrigin = getServiceOrigin(
    serviceConfigsServiceConfig,
    serverRequest,
  );

  return JSON.stringify({...serviceConfigsServiceConfig, urlOrigin});
}

export function getServiceConfigsServiceConfig():
  | ServiceConfigurationV1
  | undefined {
  const {'service-config-provider': serviceConfigProvider} =
    useFeatureServices();

  const serviceConfigsServiceConfig =
    serviceConfigProvider.configs['service-config'];

  if (!serviceConfigsServiceConfig) {
    return undefined;
  }

  return serviceConfigsServiceConfig;
}
