import {Slider} from '@mui/material';
import {withStyles} from '@mui/styles';

export const RangeSliderStyled = withStyles({
  root: {
    color: '#001e50',
    height: 3,
    padding: '15px 0',
    position: 'relative',
  },
  thumb: {
    'width': '16px',
    'height': '16px;',
    'marginTop': '-6px',
    '&:hover': {
      boxShadow: '0px 0px 0px 4px #DBDFF1',
    },
    '&:active': {
      boxShadow: '0px 0px 0px 8px #DBDFF1',
    },
    '&:focus': {
      border: '2px solid #37C1EF',
    },
    "&[data-index='0']": {
      '& .MuiSlider-valueLabel': {
        left: '-17px',
      },
    },
    "&[data-index='1']": {
      '& .MuiSlider-valueLabel': {
        left: '0',
      },
    },
  },
  active: {},
  valueLabel: {
    'left': 'calc(-50%)',
    'top': -20,
    '& *': {
      background: 'transparent',
      color: '#001e50',
    },
    'color': '#001E50',
    'lineHeight': '36px',
    'fontSize': '10px',
    'fontFamily': 'var(--font-family-head,vw-head,Helvetica,Arial,sans-serif)',
    'fontWeight': 300,
  },
  track: {
    height: 3,
  },
  rail: {
    height: 3,
    opacity: 1,
    backgroundColor: '#E4E4E4',
  },
  mark: {
    width: '1px',
    height: '9px',
    marginTop: '-3px',
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
  markLabel: {
    'color': '#001E50',
    'lineHeight': '36px',
    'fontSize': '12px',
    'fontFamily': 'var(--font-family-head,vw-head,Helvetica,Arial,sans-serif)',
    'transform': 'translateX(-100%)',
    'left': '100% !important',
    'top': '-40px !important',
    "&[data-index='0']": {
      transform: 'none',
      left: '0 !important',
    },
  },
})(Slider);

export const distanceSliderMarks = [
  {
    value: 50,
  },
  {
    value: 100,
  },
  {
    value: 150,
  },
];
