import {Infer, array, is, object, optional, string, validate} from 'superstruct';
import {useFeatureServices} from './use-feature-services';

const mastheadBackgroundJsonObject = object({
  alt: optional(string()),
  backgroundType: optional(string()),
  mobileUrl: optional(string()),
  desktopUrl: optional(string()),
  useScene7Src: optional(string()),
  scene7ImgExtension: optional(string()),
  imageHostUrl: optional(string())
});

const mastheadDescriptionJsonObject = object({
  subTitle: optional(string()),
  title: optional(string()),
  description: optional(string()),
  textColor: optional(string()),
  alignment: optional(string()),
  cssStyling: optional(string())
});

const mastheadFilterSectionJsonObject = object({
  title: optional(string()),
  searchCta: optional(string()),
});

const MastheadContentJsonObject = object({
  mastheadBackground: optional(array(mastheadBackgroundJsonObject)),
  description: optional(array(mastheadDescriptionJsonObject)),
  filtersContent: optional(array(mastheadFilterSectionJsonObject)),
});

export type MastheadContent = Infer<typeof MastheadContentJsonObject>;
export type MastheadBackground = Infer<typeof mastheadBackgroundJsonObject>;
export function useFeatureAppContent(): MastheadContent {
  const {'fa-content-service': contentService} = useFeatureServices();
  const content = contentService?.content;
  if (is(content,MastheadContentJsonObject)){
    return content ;
  } 
  validate(content, MastheadContentJsonObject);
  return {}
}
