import {
  ButtonAppearance,
  ButtonCustom,
  DisableFocusContainer,
  DropdownCustom,
  DropdownOption,
  dropdownOptionType,
  Richtext,
  TextCustom,
} from '@vw-marketing/us-components';
import React, {useEffect, useRef} from 'react';
import {useFeatureAppConfig} from '../../hooks/use-feature-app-config';
import {useFeatureServices} from '../../hooks/use-feature-services';
import {useTrackingManager} from '../../hooks/use-tracking-manager';
import {useZipData} from '../../hooks/use-zip-data';
import {
  onVWBasicFilterSearchButtonClick,
  onVWBasicFilterSelectClick,
} from '../../tagging/tagging-helpers';
import {FilterMileage, FilterPrice} from '../../typing/filters';
import {ALL_MODELS_OPTION, AppConstants} from '../../utils/app-constants';
import FilterContentDropdown from '../filter-content-dropdown';
import {InputZip} from '@vw-marketing/us-components';
import RangeSlider from '../range-slider';
import {
  filterLabelTextStyle,
  filterTitleTextOverrides,
  inputTextStyle,
  searchButtonTextStyle,
} from './helpers';
import {
  StyledSearchContainer,
  StyledTitleContainer,
  StyleZipContainer,
  StyledFiltersContainer,
  StyledSearchButtonContainer,
  StyledDropdownWrapper,
  StyledRangeSliderWrapper,
  StyledFilterLabelWrapper,
} from './styles';
import {VALIDATE_ZIP} from '../../store/queries/zip';

interface MastheadSearchProps {
  title?: string;
  searchCta?: string;
  models?: string[];
}
const defaultMileage: FilterMileage = {
  minDefault: 0,
  maxDefault: 70000,
  label: 'Vehicle Mileage',
  min: 0,
  max: 70000,
  step: 10000,
  marksAmount: 8,
  displayMark: 'on',
};

const defaultPrice: FilterPrice = {
  minDefault: 5000,
  maxDefault: 50000,
  label: 'Price',
  min: 5000,
  max: 50000,
  step: 5000,
  marksAmount: 10,
  displayMark: 'on',
  currencySymbol: '$',
};

const filterNameMap = {
  model: 'CG',
  zip: 'ZIP',
  price: 'PR',
  mileage: 'KR',
};

const MastheadSearch: React.FC<MastheadSearchProps> = props => {
  const {title, models} = props;
  const {zipData} = useZipData();
  const trackingManager = useTrackingManager();
  const {filtersConfig, resultsFAName, resultsPageName} = useFeatureAppConfig();
  const {
    'zip-manager': zipManager,
    'navigation-service': navigationService,
  } = useFeatureServices();

  let {mileage = defaultMileage, price = defaultPrice} = {...filtersConfig};
  const [startTracking, setStartTracking] = React.useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const dropdownOptions = dropdownRef.current?.querySelectorAll(
        '.dropdown-option',
      );
      dropdownOptions?.forEach((option, i) => {
        if (i > 0) {
          option.classList.add('notranslate');
        }
      });
    });

    if (dropdownRef.current) {
      observer.observe(dropdownRef.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => observer.disconnect();
  }, []);

  const getTrackingLinkData = (name: string) => {
    return {url: window.location.href, name};
  };

  const [userSelection, setUserSelection] = React.useState<any>({
    model: (models && models[0]) || '',
    price: [],
    mileage: [],
  });
  const [userOdometer, setUserOdometer] = React.useState([
    mileage.minDefault || 0,
    mileage.maxDefault || 0,
  ]);
  const [userPrice, setUserPrice] = React.useState([
    price.minDefault || 0,
    price.maxDefault || 0,
  ]);

  const [disableSearch, setDisableSearch] = React.useState(false);

  const getTrackingFilterData = () => {
    const filterList = [
      {
        type: 'single_value',
        name: filterNameMap.zip,
        values: [zipData?.zip],
      },
      {
        type: 'single_value',
        name: filterNameMap.model,
        values: [userSelection?.model],
      },
      {
        type: 'range',
        name: filterNameMap.mileage,
        lowerbound:
          userOdometer[0] !== -1 ? userOdometer[0] : mileage.minDefault,
        upperbound:
          userOdometer[1] !== -1 ? userOdometer[1] : mileage.maxDefault,
      },
      {
        type: 'range',
        name: filterNameMap.price,
        lowerbound: userPrice[0] !== -1 ? userPrice[0] : price.minDefault,
        upperbound: userPrice[1] !== -1 ? userPrice[1] : price.maxDefault,
      },
    ];

    return {
      filterlist: filterList,
    } as any;
  };

  /**
   * Handles user input
   * @param value
   */
  const onHandleModelSelection = (value: dropdownOptionType) => {
    let selectedValue = models?.find(model => model === value.value);
    setUserSelection({...userSelection, model: selectedValue});

    const filter = {
      name: filterNameMap.model,
      type: 'single_value',
      values: [selectedValue],
    };
    startTracking &&
      onVWBasicFilterSelectClick(
        filter,
        getTrackingLinkData('Vehicle Model'),
        trackingManager,
      );
    setStartTracking(true);
  };

  const onHandleMilageSelection = () => {
    const filter = {
      name: filterNameMap.mileage,
      type: 'range',
      lowerbound: userOdometer[0]?.toString(),
      upperbound: userOdometer[1]?.toString(),
    };
    onVWBasicFilterSelectClick(
      filter,
      getTrackingLinkData('Vehicle Mileage'),
      trackingManager,
    );

    setUserSelection({...userSelection, mileage: userOdometer});
  };

  const onHandlePriceSelection = () => {
    const filter = {
      name: filterNameMap.price,
      type: 'range',
      lowerbound: userPrice[0]?.toString(),
      upperbound: userPrice[1]?.toString(),
    };
    onVWBasicFilterSelectClick(
      filter,
      getTrackingLinkData('Price'),
      trackingManager,
    );

    setUserSelection({...userSelection, price: userPrice});
  };

  /**
   * Handles search redirect
   */
  const onHandleUserSearch = () => {
    const urlParams = new URLSearchParams();
    if (userSelection.model && userSelection.model !== ALL_MODELS_OPTION) {
      urlParams.set('modelName', userSelection.model);
    }
    if (userSelection.mileage.length) {
      urlParams.set(
        'odometer',
        `${userSelection.mileage[0]},${userSelection.mileage[1]}`,
      );
    }
    if (userSelection.price.length) {
      let selectedPrice = userSelection.price;
      if (price?.max && selectedPrice[1] >= price?.max) selectedPrice[1] = -1;
      urlParams.set('price', `${selectedPrice[0]},${selectedPrice[1]}`);
    }
    const navigateResults = navigationService.navigateTo(
      resultsPageName ? resultsPageName : AppConstants.resultsPageName,
      {
        [resultsFAName ? resultsFAName : AppConstants.resultsFAName]: {
          pathname: '/',
          search: `?${urlParams.toString()}`,
        },
      },
    );

    onVWBasicFilterSearchButtonClick(
      getTrackingFilterData(),
      getTrackingLinkData('Search'),
      trackingManager,
    );

    navigateResults?.push();
  };

  const onHandleZipCodeStatus = (status: boolean) => {
    setDisableSearch(status);
  };

  const onHandleOdometerChange = (
    _: any,
    newValue: number[] | number,
    activeThumb: number,
  ) => {
    const minDistance = mileage.step || 0;
    const minMileage = mileage.min || 0;
    const maxMileage = mileage.max || 0;
    if (
      !newValue ||
      typeof newValue[0] === 'undefined' ||
      !newValue[1] ||
      !mileage
    )
      return;
    let processedValue = newValue as number[];
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[0] < minMileage || newValue[1] > maxMileage) return;

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.max(newValue[0], maxMileage - minDistance);
        processedValue = [clamped, clamped + minDistance];
      } else {
        let clamped = Math.min(newValue[1], maxMileage);
        if (clamped <= minMileage) {
          clamped = minMileage + minDistance;
        }
        processedValue = [clamped - minDistance, clamped];
      }
    }

    setUserOdometer(processedValue as number[]);
  };

  const onHandlePriceChange = (
    _: any,
    newValue: number[] | number,
    activeThumb: number,
  ) => {
    if (!newValue || !newValue[0] || !newValue[1]) return;
    const minDistance = price.step || 0;
    const minPrice = price.min || 0;
    const maxPrice = price.max || 0;
    let processedValue = newValue as number[];
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[0] < minPrice || newValue[1] > maxPrice) return;

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.max(newValue[0], maxPrice - minDistance);
        processedValue = [clamped, clamped + minDistance];
      } else {
        let clamped = Math.min(newValue[1], maxPrice);
        if (clamped <= minPrice) {
          clamped = minPrice + minDistance;
        }
        processedValue = [clamped - minDistance, clamped];
      }
    }

    setUserPrice(processedValue as number[]);
  };
  /** Auxiliary functions */
  const formatMileageNumber = (number: number) => number.toLocaleString();
  const formatPriceNumber = (number: number) =>
    price?.currencySymbol +
    number.toLocaleString() +
    `${number === price.max ? '+' : ''}`;

  const getStepsByRange = (
    steps: number,
    step: number,
    minValue: number,
    maxValue: number,
    format: (number: number) => string,
    initialIndex: number,
  ) => {
    const stepsMarks = [
      {
        value: minValue,
        label: format(minValue),
      },
    ];
    for (let index = initialIndex; index < steps; index++) {
      stepsMarks.push({
        value: index * step,
        label: '',
      });
    }

    stepsMarks.push({
      value: maxValue,
      label: format(maxValue),
    });
    return stepsMarks;
  };
  if (!models) return <></>;
  return (
    <StyledSearchContainer className="search-container">
      <StyledTitleContainer className="search-title-container">
        <Richtext
          markdown={title || ''}
          overrides={filterTitleTextOverrides}
          dangerouslyParseRawHTML
        />
      </StyledTitleContainer>
      <StyleZipContainer
        className="search-zip-container"
        data-testid="search-zip-container"
      >
        <InputZip
          placeholder={'Enter your ZIP Code'}
          maxLength={5}
          updateOnBlur
          hideLocationText
          showDeleteButton
          scrollToTopOnUpdate
          onHandleZipCodeStatus={onHandleZipCodeStatus}
          errorText="Invalid Zip Code"
          zipManager={zipManager}
          validateZipQuery={VALIDATE_ZIP}
          useZipData={useZipData}
        />
      </StyleZipContainer>
      <StyledFilterLabelWrapper className="search-filter-label">
        <TextCustom {...filterLabelTextStyle}>Filter By</TextCustom>
      </StyledFilterLabelWrapper>
      <StyledFiltersContainer className="search-filters-container">
        <StyledDropdownWrapper
          className="search_custom-dropdown"
          ref={dropdownRef}
        >
          <DropdownCustom
            onChange={onHandleModelSelection}
            value={{
              value: userSelection.model,
              label: userSelection.model,
            }}
            inputTextAppearance={inputTextStyle}
          >
            {models?.map((model: string) => (
              <DropdownOption value={model} label={model} key={model} />
            ))}
          </DropdownCustom>
        </StyledDropdownWrapper>
        <FilterContentDropdown label={mileage?.label || ''}>
          <StyledRangeSliderWrapper
            id="discrete-slider-small-steps"
            className="range-slider-wrapper mileage-container"
          >
            <RangeSlider
              className="mileage-container"
              aria-label={'Mileage'}
              aria-labelledby="discrete-slider-small-steps"
              value={userOdometer}
              step={null}
              min={(mileage.min || 0) - (mileage?.step || 0)}
              max={(mileage.max || 0) + (mileage?.step || 0)}
              onChange={onHandleOdometerChange}
              onChangeCommitted={onHandleMilageSelection}
              valueLabelFormat={formatMileageNumber}
              marks={getStepsByRange(
                mileage.marksAmount || 10,
                mileage.step || 10000,
                mileage?.min || 0,
                mileage?.max || 0,
                formatMileageNumber,
                0,
              )}
              valueLabelDisplay={mileage?.displayMark || 'auto'}
            />
          </StyledRangeSliderWrapper>
        </FilterContentDropdown>
        <FilterContentDropdown label={price?.label || ''}>
          <StyledRangeSliderWrapper
            id="discrete-slider-small-steps"
            className="range-slider-wrapper price-container"
          >
            <RangeSlider
              value={userPrice}
              step={null}
              min={(price?.min || 0) - (price?.step || 0)}
              max={(price?.max || 0) + (price?.step || 0)}
              aria-label={'Price'}
              aria-labelledby="discrete-slider-small-steps"
              onChange={onHandlePriceChange}
              onChangeCommitted={onHandlePriceSelection}
              valueLabelFormat={formatPriceNumber}
              className="price-container"
              marks={getStepsByRange(
                price.marksAmount || 10,
                price.step || 5000,
                price?.min || 0,
                price?.max || 0,
                formatPriceNumber,
                1,
              )}
              valueLabelDisplay={mileage?.displayMark || 'auto'}
            />
          </StyledRangeSliderWrapper>
        </FilterContentDropdown>
      </StyledFiltersContainer>
      <StyledSearchButtonContainer data-testid="user-search-button">
        <DisableFocusContainer>
          <ButtonCustom
            appearance={ButtonAppearance.Primary}
            stretchContent
            onClick={onHandleUserSearch}
            disabled={disableSearch}
          >
            <TextCustom {...searchButtonTextStyle}>Search </TextCustom>
          </ButtonCustom>
        </DisableFocusContainer>
      </StyledSearchButtonContainer>
    </StyledSearchContainer>
  );
};

export default MastheadSearch;
