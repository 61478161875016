import {makeVar} from '@apollo/client';
import {CategoryModels} from '../typing/model';

export const modelsConfigVar = makeVar<CategoryModels[]>([]);

export interface GlobalModelData {
  modelName?: string;
  year: number;
  slug?: string;
}
export const globalModelData = makeVar<GlobalModelData | undefined>(undefined);
