import React from 'react';
import isBrowser from 'is-browser';
import fetch from 'cross-fetch';
import {ApolloProvider} from '@apollo/client/react';
import {useFeatureAppConfig} from '../hooks/use-feature-app-config';
import {useFeatureServices} from '../hooks/use-feature-services';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  defaultDataIdFromObject,
} from '@apollo/client';

export interface DataServerProps {
  serverUrl?: string;
  children: React.ReactNode;
}

export const DataServer: React.FC<DataServerProps> = (
  props: DataServerProps,
) => {
  const {children} = props;
  const {graphQLServerUrl} = useFeatureAppConfig();
  const {configs} = useFeatureServices()['service-config-provider'];
  const graphQLEnvServer = configs['graphql-server']
    ? new URL(
        configs['graphql-server'].urlPath || '/',
        configs['graphql-server']?.urlOrigin,
      ).toString()
    : '';
  console.log(graphQLEnvServer || graphQLServerUrl);
  const graphQLServer = graphQLEnvServer || graphQLServerUrl || '';
  !graphQLServer && console.error('No value provided for graphQLServer');

  const httpLink = createHttpLink({
    uri: graphQLServer,
    fetch: fetch,
  });

  const typePolicies = {
    Query: {},
  };

  const cache = new InMemoryCache({
    typePolicies,
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        default:
          return defaultDataIdFromObject(responseObject);
      }
    },
  });

  const client = new ApolloClient({
    link: httpLink,
    cache: cache,
    ssrMode: !isBrowser,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
