import {FeatureAppEnvironment} from '@feature-hub/core';
import React from 'react';
import { FeatureAppConfig, FeatureAppDependencies } from '../typing/main';
import {useSafeContext} from './use-safe-context';

export type InventoryFeatureAppEnvironment = FeatureAppEnvironment<
  FeatureAppDependencies,
  FeatureAppConfig
>;

export const FeatureAppEnvironmentContext = React.createContext<
  InventoryFeatureAppEnvironment | undefined
>(undefined);

export function useFeatureAppEnvironment(): InventoryFeatureAppEnvironment {
  return useSafeContext(FeatureAppEnvironmentContext, 'featureAppEnvironment');
}
