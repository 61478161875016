import {useEffect, useState} from 'react';
import {AppConstants} from '../utils/app-constants';
import {Scene7Video} from '../typing/main';

export class FetchS7VideoError extends Error {
  public constructor(message: string, public readonly url?: string) {
    super(message);
  }
}

export async function fetchScene7Video(url: string): Promise<any> {
  const response = await fetch(url, {});
  const jsonResponse = await response?.json();

  if (response.status >= 500) {
    throw new FetchS7VideoError(jsonResponse.message, url);
  }
  return jsonResponse;
}

export const useFetchScene7Video = (
  videoDAMPath?: string,
  originUrl?: string,
) => {
  const [data, setData] = useState<Scene7Video[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const s7VideoUrl = `${originUrl}${videoDAMPath}${AppConstants.Scene7VideoExtension}`;

  useEffect(() => {
    if (!data) {
      setLoading(true);
      fetchScene7Video(s7VideoUrl)
        .then((response) => {
          setData(response);
          setLoading(false);
        })
        .catch((error: Error) => {
          setError(error);
          setLoading(false);
        });
    }
  }, []);

  return {data, loading, error};
};
