import {LocaleServiceV1} from '@volkswagen-onehub/locale-service';
import {PriceData} from '../typing/inventory';

export const currencySymbolMap = {
  US: '$',
};

export const getPriceData = (localService: LocaleServiceV1): PriceData => {
  const symbol = currencySymbolMap[localService.currency];
  return {
    code: localService.currency,
    symbol,
  };
};
