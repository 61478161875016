import React from 'react';
import {IZipConfig} from '../typing/zip-types';
import {useSafeContext} from './use-safe-context';

export const ZipContext = React.createContext<IZipConfig | undefined>(
  undefined,
);

export function useZipData(): IZipConfig {
  return useSafeContext(ZipContext, 'Zip Context');
}
