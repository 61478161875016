import {gql} from '@apollo/client';

export const VALIDATE_ZIP = gql`
  query validateZip($zipcode: String!) {
    validateZip(zipcode: $zipcode) {
      zip
      valid
      lat
      lng
      country
      city
      state
    }
  }
`;

export const GET_ZIP_FROM_COORD = gql`
  query zipFromCoordinates($lat: Float!, $lng: Float!) {
    zipFromCoordinates(lat: $lat, lng: $lng) {
      zip
      valid
      lat
      lng
      country
      city
      state
    }
  }
`;
